<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Edit User Document
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">
    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users', userId]">User</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users', userId, 'documents']">Document List</a>
                        </li>
                        <li class="breadcrumb-item active"
                            aria-current="page">
                            Edit User Document
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-8">
                <div class="alert alert-success alert-dismissable"
                     role="alert"
                     *ngIf="alert.display">
                    <button type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p class="mb-0"> {{ alert.message }} </p>
                </div>

                <form [formGroup]="updateDocumentStatusForm"
                      novalidate
                      (ngSubmit)="updateDocumentStatus()">
                    <div class="block">
                        <div class="block-header block-header-default block-header-rtl">
                            <h3 class="block-title">
                                <em class="text-muted"> {{ documentId }} </em>
                                <span *ngIf="document"
                                      [ngClass]="{'badge': true, 'badge-success': document.status == 'VALIDATED', 'badge-warning': document.status == 'PENDING_REVIEW', 'badge-danger': document.status == 'FAILED'}">
                                    {{ document.status }}
                                </span>
                            </h3>
                            <div class="block-options">
                                <button type="button"
                                        class="btn btn-sm btn-secondary"
                                        data-toggle="modal"
                                        data-target="#img-preview"
                                        [disabled]="!isFilePresent">
                                    <i class="nav-main-link-icon fas fa-file-image"></i> Preview
                                </button>
                                &nbsp;
                                <button type="reset"
                                        class="btn btn-sm btn-secondary"
                                        (click)="downloadDocument()">
                                    <i class="nav-main-link-icon fas fa-download"></i> Download
                                </button>
                                &nbsp;
                                <button type="reset"
                                        class="btn btn-sm btn-secondary"
                                        [routerLink]="['/users',userId,'documents', 'add', 'new']">
                                    <i class="nav-main-link-icon fas fa-file-upload"></i> Add New Version
                                </button>
                                &nbsp;
                                <button type="button"
                                        (click)="updateDocumentStatus()"
                                        [disabled]="updateDocumentStatusForm.invalid || updateDocumentStatusForm.prestine"
                                        class="btn btn-sm btn-primary"
                                        data-wizard="finish">
                                    <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                    <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Update
                                </button>
                                &nbsp;
                                <button type="button"
                                        class="btn btn-warning btn-sm"
                                        data-toggle="modal"
                                        data-target="#sendDocumentToMtModal">
                                    <i [ngClass]="{'fa fa-asterisk fa-spin': sendDocumentToMtInProgress}"></i><i
                                       class="fa fa-rocket"
                                       aria-hidden="true"></i> Send document to MoneyTrans
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <form [formGroup]="userDetailsForm"
              novalidate>
            <h2 class="content-heading border-bottom mb-4 pb-2">User Details</h2>
            <div class="row">
                <div class="col-md-12">
                    <div class="block">
                        <div class="block-content">
                            <div class="row justify-content-center py-sm-3 py-md-5">
                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="firstName">Firstname</label>
                                        <input class="form-control"
                                               id="firstName"
                                               name="firstName"
                                               readonly
                                               formControlName="firstName" />
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="lastName">Lastname</label>
                                        <input class="form-control"
                                               id="lastName"
                                               name="lastName"
                                               readonly
                                               formControlName="lastName" />
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control"
                                               id="email"
                                               name="email"
                                               readonly
                                               formControlName="email" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <h2 class="content-heading border-bottom mb-4 pb-2">Address</h2>
            <div class="row">
                <div class="col-md-12">
                    <div class="block">
                        <div class="block-content">
                            <div class="row justify-content-center py-sm-3 py-md-5">
                                <ng-container formGroupName="address">
                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="streetName">Street Name</label>
                                            <input class="form-control"
                                                   id="streetName"
                                                   name="streetName"
                                                   readonly
                                                   formControlName="streetName" />
                                        </div>
                                    </div>

                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="streetNumber">Street Number</label>
                                            <input class="form-control"
                                                   id="streetNumber"
                                                   name="streetNumber"
                                                   readonly
                                                   formControlName="streetNumber" />
                                        </div>
                                    </div>

                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="postCode">Post Code</label>
                                            <input class="form-control"
                                                   id="postCode"
                                                   name="postCode"
                                                   readonly
                                                   formControlName="postCode" />
                                        </div>
                                    </div>

                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <input class="form-control"
                                                   id="city"
                                                   name="city"
                                                   readonly
                                                   formControlName="city" />
                                        </div>
                                    </div>

                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="state">State</label>
                                            <input class="form-control"
                                                   id="state"
                                                   name="state"
                                                   readonly
                                                   formControlName="state" />
                                        </div>
                                    </div>

                                    <div class="col-sm-10 col-md-8">
                                        <div class="form-group">
                                            <label for="country">Country</label>
                                            <input class="form-control"
                                                   id="country"
                                                   name="country"
                                                   readonly
                                                   formControlName="country" />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>

        <h2 class="content-heading border-bottom mb-4 pb-2">Comments</h2>
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="updateDocumentStatusForm"
                      novalidate>
                    <div class="block">
                        <div class="block-content"
                             *ngIf="document">
                            <div *ngFor="let comment of document.comments"
                                 class="alert alert-warning"
                                 role="alert">
                                <p class="mb-0"> {{ comment.timestamp * 1000 | date: 'yyyy/MM/dd h:mm:ss a' }}: {{
                                    comment.text }} </p>
                            </div>
                        </div>
                    </div>

                    <div class="block">
                        <div class="block-content">
                            <div class="row justify-content-center py-sm-3 py-md-5">
                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="documentStatus">Document Status</label>
                                        <select class="form-control"
                                                id="documentStatus"
                                                name="status"
                                                formControlName="status">
                                            <option value="VALIDATED">VALIDATED</option>
                                            <option value="PENDING_REVIEW">PENDING_REVIEW</option>
                                            <option value="FAILED">FAILED</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="dateOfIssue">Comment</label>
                                        <textarea name="comment"
                                                  id="comment"
                                                  formControlName="comment"
                                                  class="form-control">
                                                </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <h2 class="content-heading border-bottom mb-4 pb-2">Document Metadata</h2>
        <div class="row">
            <div class="col-md-12">
                <form [formGroup]="updateMetadataForm"
                      novalidate
                      (ngSubmit)="updateMetadata()">
                    <div class="block">
                        <div class="block-content">
                            <div class="row justify-content-center py-sm-3 py-md-5">
                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="documentNumber">Document Number</label>
                                        <input class="form-control"
                                               id="documentNumber"
                                               name="documentNumber"
                                               formControlName="documentNumber" />
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="dateOfIssue">Date of issue</label>
                                        <input class="form-control"
                                               id="dateOfIssue"
                                               name="dateOfIssue"
                                               placeholder="DD/MM/YYYY"
                                               formControlName="dateOfIssue" />
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <div class="form-group">
                                        <label for="expiryDate">Expiry date</label>
                                        <input class="form-control"
                                               id="expiryDate"
                                               name="expiryDate"
                                               placeholder="DD/MM/YYYY"
                                               formControlName="expiryDate" />
                                    </div>
                                </div>

                                <div class="col-sm-10 col-md-8">
                                    <button type="submit"
                                            [disabled]="updateMetadataForm.invalid || updateMetadataForm.prestine"
                                            class="btn btn-primary"
                                            data-wizard="finish">
                                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Attach Metadata
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <h2 class="content-heading border-bottom mb-4 pb-2">Document Versions</h2>
        <div class="row">
            <div class="col-md-12">
                <div class="block">
                    <div class="block-content">
                        <p class="font-size-sm text-muted">

                        </p>
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Document Type</th>
                                        <th>Document Version</th>
                                        <th style="width: 15%;">Status</th>
                                        <th style="width: 15%;">DateTime</th>
                                        <th class="text-center"
                                            style="width: 100px;">Actions</th>
                                    </tr>
                                </thead>

                                <div *ngIf="loading">
                                    <div class="spinner-border"
                                         style="width: 3rem; height: 3rem;"
                                         role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow"
                                         style="width: 3rem; height: 3rem;"
                                         role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>


                                <tbody *ngIf="!loading">
                                    <tr *ngFor="let version of versions">
                                        <td class="font-size-sm"><em class="text-muted">{{ version.type }}</em></td>
                                        <td class="font-size-sm">
                                            <em class="text-muted">{{ version.documentId }}</em>
                                            &nbsp;
                                            <span *ngIf="version.latest"
                                                  [ngClass]="{'badge': true, 'badge-success': true}">
                                                latest
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                  [ngClass]="{'badge': true, 'badge-success': version.status == 'VALIDATED', 'badge-warning': version.status == 'PENDING_REVIEW', 'badge-danger': version.status == 'FAILED'}">
                                                {{ version.status }}
                                            </span>
                                        </td>
                                        <td class="font-w600 font-size-sm">
                                            <a href="#">{{ version.timestamp * 1000 | date: 'yyyy/MM/dd h:mm:ss a'
                                                }}</a>
                                        </td>
                                        <td class="text-center">
                                            <div class="btn-group">
                                                <button type="button"
                                                        [routerLink]="['/users', userId, 'documents', version.documentId]"
                                                        class="btn btn-sm btn-primary"
                                                        data-toggle="tooltip"
                                                        title="Edit">
                                                    <i class="fa fa-fw fa-pencil-alt"></i>
                                                </button>
                                                <button type="button"
                                                        class="btn btn-sm btn-primary"
                                                        data-toggle="tooltip"
                                                        title="Delete">
                                                    <i class="fa fa-fw fa-times"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade"
     id="sendDocumentToMtModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="sendDocumentToMtTitle"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="sendDocumentToMtTitle">Send to MoneyTrans</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you confirm, you want to send <strong>{{ document?.type }} to MoneyTrans</strong>?
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="sendDocumentToMt()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Send</button>
            </div>
        </div>
    </div>
</div>

<!-- render images -->
<div class="modal"
     id="img-preview"
     tabindex="-2"
     role="dialog"
     aria-labelledby="img-preview"
     aria-hidden="true"
     *ngIf="isImage">
    <div class="modal-dialog modal-lg modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Preview</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm"
                     style="display: flex; justify-content: center; align-items: center;">
                    <img [src]="documentFile">
                </div>
            </div>
        </div>
    </div>
</div>


<!-- render images -->
<div class="modal"
     id="img-preview"
     tabindex="-2"
     role="dialog"
     aria-labelledby="img-preview"
     aria-hidden="true"
     *ngIf="!isImage">
    <div class="modal-dialog modal-lg modal-dialog-centered"
         role="document">
        <div class="modal-content">
            <div class="block block-rounded block-transparent mb-0">
                <div class="block-header block-header-default">
                    <h3 class="block-title">Preview</h3>
                    <div class="block-options">
                        <button type="button"
                                class="btn-block-option"
                                data-dismiss="modal"
                                aria-label="Close">
                            <i class="fa fa-fw fa-times"></i>
                        </button>
                    </div>
                </div>
                <div class="fs-sm"
                     style="display: flex; justify-content: center; align-items: center;">

                    <ng-container *ngIf="isFileLoading">
                        <div class="js-wizard-simple block">
                            <div class="text-center"
                                 style="
                              position: fixed;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                            ">
                                <div class="spinner-border"
                                     style="width: 3rem; height: 3rem"
                                     role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <pdf-viewer [src]="src"
                                [render-text]="true"
                                [original-size]="false"
                                (after-load-complete)="afterLoadComplete($event)"
                                style="width: 400px; height: 600px">
                    </pdf-viewer>
                </div>
            </div>
        </div>
    </div>
</div>