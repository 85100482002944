export const environment = {
   production: 'eu-west-3',
   cognito: {
    Auth: {
      clientId: '483jve0c2376gfkqssrjsq2n1c',
      clientSecret: 'oil0b8ibvfq44hd5elm4ebd0l8fosu64uj7icmoo9qkls9l3f3e',
    },
    region: 'eu-west-3',
    userPoolId: 'eu-west-3_QSliNJbqd',
    userPoolWebClientId: '483jve0c2376gfkqssrjsq2n1c',
    walletPlanBaseUrl: 'https://wallet-plans.prod.nbk-cg.com'
   },
   wallet: {
    walletGwBaseUrl: 'https://wallet-gw.prod.nbk-cg.com',
    mobileServiceBaseUrl: 'https://mobile-payment-gw.prod.nbk-cg.com',
    planBaseUrl: 'https://wallet-plans.prod.nbk-cg.com',
    paymentServiceBaseUrl: 'https://card-payment-gw.prod.nbk-cg.com',
    complianceServiceBaseUrl: 'https://wallet-compliance.prod.nbk-cg.com',
    fundRaisingServiceBaseUrl: 'https://fund-raising-gw.prod.nbk-cg.com',
    bassServiceBaseUrl: 'https://bass-gw.prod.nbk-cg.com',
    signingServiceBaseUrl: 'https://wallet-signing.prod.nbk-cg.com',
    analyticsServiceBaseUrl: 'https://wallet-analytics-ng.prod.nbk-cg.com',
    walletEventsBaseUrl: 'https://wallet-events.prod.nbk-cg.com',
    tontineRemittanceServiceBaseUrl: 'undefined',
    nbkCrmBaseUrl: 'https://nbk-crm.prod.nbk-cg.com',
  }
};
