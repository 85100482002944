
<div class="bg-body-light">
    <div class="content content-full">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
        <h1 class="flex-sm-fill h3 my-2">
          Wallet User Account
        </h1>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button" class="btn btn-block btn-light d-flex justify-content-between align-items-center" data-toggle="class-toggle" data-target="#horizontal-navigation-hover-centered" data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->
    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',account.userId]">User</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users',account.userId, 'accounts']">Accounts</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Account
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navigation -->
    <div id="horizontal-navigation-hover-normal" class="d-none d-lg-block mt-2 mt-lg-0">
    <ul class="nav-main nav-main-horizontal nav-main-hover">

        <li class="nav-main-item">
            <a class="nav-main-link active" [routerLink]="['/users',account.userId,'accounts',account.accountId,'transactions']">
                <i class="nav-main-link-icon fa fa-history"></i>
                <span class="nav-main-link-name">Transactions</span>
            </a>
        </li>

        <li class="nav-main-item">
            <a class="nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="false" href="#">
                <i class="nav-main-link-icon fa fa-money-bill-wave"></i>
                <span class="nav-main-link-name">Top Up</span>
            </a>
            <ul class="nav-main-submenu">
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'topUp']">
                        <i class="nav-main-link-icon fab fa-cc-stripe"></i>
                        <span class="nav-main-link-name">Stripe</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'topUp', 'mtn']">
                        <i class="nav-main-link-icon fa fa-mobile"></i>
                        <span class="nav-main-link-name">MTN</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'topUp', 'paypal']">
                        <i class="nav-main-link-icon fab fa-paypal"></i>
                        <span class="nav-main-link-name">Paypal</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'topUp']">
                        <i class="nav-main-link-icon fa fa-ticket-alt"></i>
                        <span class="nav-main-link-name">Voucher</span>
                    </a>
                </li>
            </ul>
        </li>

        <li class="nav-main-item">
            <a class="nav-main-link nav-main-link-submenu" data-toggle="submenu" aria-haspopup="true" aria-expanded="false" href="#">
                <i class="nav-main-link-icon fa fa-money-bill-wave"></i>
                <span class="nav-main-link-name">Cash Out</span>
            </a>
            <ul class="nav-main-submenu">
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'cash-out','bank-account']">
                        <i class="nav-main-link-icon fa fa-university"></i>
                        <span class="nav-main-link-name">Bank Account</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'cash-out','bank-card']">
                        <i class="nav-main-link-icon fa fa-credit-card"></i>
                        <span class="nav-main-link-name">Debit Card</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'cashOut','mtn']">
                        <i class="nav-main-link-icon fas fa-mobile"></i>
                        <span class="nav-main-link-name">MTN</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'cash-out','bank-card']">
                        <i class="nav-main-link-icon fas fa-money-bill-alt"></i>
                        <span class="nav-main-link-name">Cash</span>
                    </a>
                </li>
                <li class="nav-main-item">
                    <a class="nav-main-link" [routerLink]="['/users',account.userId,'accounts',account.accountId,'cash-out','bank-account']">
                        <i class="nav-main-link-icon fa fa-shopping-cart"></i>
                        <span class="nav-main-link-name">Order Payment Card</span>
                    </a>
                </li>
            </ul>
        </li>
    </ul>
    </div>
    <!-- END Navigation -->

  </div>

<div class="content">
    <div class="row">
      <div class="col-md-6">
<div class="js-wizard-simple block">
    <!-- Form -->
    <form [formGroup]="walletAccountForm">
        <!-- Steps Content -->
        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
            <!-- Step 1 -->
            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
<!--                 <div class="progress push" style="height: 10px;">
                    <div class="progress-bar" role="progressbar" style="width: 30%;" aria-valuenow="{{ progressBar.valueNow }}" aria-valuemin="0" aria-valuemax="100"></div>
                </div> -->
                <div class="form-group">
                     <h1 class="font-w400">{{ account.balance | currency: 'EUR'}}</h1>

                </div>
                <div class="form-group">
                    <label for="name">Account Name</label>
                    <input class="form-control" type="text" readonly id="name" name="name" formControlName="name">
                </div>
                <div class="form-group">
                    <label for="accountType">Account Type</label>
                    <input class="form-control" type="text" readonly id="accountType" name="accountType" formControlName="accountType">
                </div>
            </div>
            <!-- END Step 1 -->

            <!-- Step 2 -->
            <div class="tab-pane" id="wizard-simple-step2" role="tabpanel">
            </div>
            <!-- END Step 2 -->
        </div>
        <!-- END Steps Content -->

        <!-- Steps Navigation -->
        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
            <div class="row">
                <div class="col-6 text-right">
                    <button type="submit" class="btn btn-primary" data-wizard="finish">
                        <i class="fa fa-check mr-1"></i> Submit
                    </button>
                </div>
            </div>
        </div>
        <!-- END Steps Navigation -->
    </form>
    <!-- END Form -->
</div>
</div>
</div>
</div>
