import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

import { UserEntity } from '../user-entity';
import { UserService } from '../user.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit {
  users: Array<UserEntity> = [];
  loading: boolean;
  pageSize: number = 10;
  currentPage: number = 1;
  totalItems: number = 0;
  searchByEmail: string = '';
  searchByMobile: string;
  searchByFirstName: string;
  searchByKyc: string;
  searchByBank: string;
  searchByLastName: string;
  searchByAlias: string;
  totalPages: any[] = [];

  @Input()
  firstLastButtons = false;

  @Input()
  nextPreviousButtons = true;

  @ViewChild('emailEl') emailEl: ElementRef<HTMLInputElement>;
  @ViewChild('lastNameEl') lastNameEl: ElementRef<HTMLInputElement>;
  @ViewChild('firstNameEl') firstNameEl: ElementRef<HTMLInputElement>;
  @ViewChild('aliasEl') aliasEl: ElementRef<HTMLInputElement>;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private errorHandlerService: ErrorHandlerService
  ) {
    this.loading = true;
    if (window.One != undefined) {
      window.One.loader('show');
    }
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.search();
      if (window.One != undefined) {
        window.One.loader('hide');
      }
    });
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
    this.search();
  }

  /** Set previous page number */
  previous() {
    const previousPage = this.currentPage - 1;
    previousPage >= 1 && this.selectPageNumber(previousPage);
  }

  /** Set next page number */
  next() {
    const nextPage = this.currentPage + 1;
    nextPage <= this.totalPages.length && this.selectPageNumber(nextPage);
  }

  convertToTime(createdAt: string): string {
    const date = new Date(+createdAt * 1000);
    return date.toLocaleString();
  }

  search() {
    this.loading = true;
    this.users = [];
    let parameters = { page: this.currentPage, limit: this.pageSize };

    if (this.searchByMobile) {
      parameters['mobileNumber'] = this.searchByMobile;
    }

    if (this.searchByAlias) {
      parameters['alias'] = this.searchByAlias;
    }

    if (this.searchByEmail) {
      parameters['email'] = this.searchByEmail;
    }

    if (this.searchByFirstName) {
      parameters['firstName'] = this.searchByFirstName;
    }

    if (this.searchByLastName) {
      parameters['lastName'] = this.searchByLastName;
    }

    if (this.searchByKyc) {
      parameters['kycStatus'] = this.searchByKyc;
    }

    if (this.searchByBank) {
      parameters['bank'] = this.searchByBank;
    }

    let queryParams = new HttpParams({ fromObject: parameters });
    this.userService
      .fetchAll(queryParams)
      .subscribe((response: WalletApiResponse<any>) => {
        if (response.status !== 'success') {
          this.loading = false;
          return;
        }
        this.users = response.data.walletAccountUsers.data;
        this.currentPage = response.data.walletAccountUsers.page;
        this.totalItems = response.data.walletAccountUsers.total;
        this.pageSize = response.data.walletAccountUsers.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  }

  onSearch(value: string, key: string): void {
    this.loading = true;
    this.users = [];
    let parameters = { page: this.currentPage, limit: this.pageSize };

    if (this.searchByMobile) {
      parameters['mobileNumber'] = this.searchByMobile;
    }

    if (this.searchByAlias) {
      parameters['alias'] = this.searchByAlias;
    }

    if (this.searchByEmail) {
      parameters['email'] = this.searchByEmail;
    }

    if (this.searchByFirstName) {
      parameters['firstName'] = this.searchByFirstName;
    }

    if (this.searchByLastName) {
      parameters['lastName'] = this.searchByLastName;
    }

    if (this.searchByKyc) {
      parameters['kycStatus'] = this.searchByKyc;
    }

    if (this.searchByBank) {
      parameters['bank'] = this.searchByBank;
    }

    let queryParams = new HttpParams({ fromObject: parameters });

    this.userService
      .fetchAll(queryParams)
      .subscribe((response: WalletApiResponse<any>) => {
        if (response.status !== 'success') {
          this.loading = false;
          return;
        }
        this.users = [];
        this.users = response.data.walletAccountUsers.data;
        this.currentPage = response.data.walletAccountUsers.page;
        this.totalItems = response.data.walletAccountUsers.total;
        this.pageSize = response.data.walletAccountUsers.limit;
        this.totalPages = new Array(Math.ceil(this.totalItems / this.pageSize));
        this.loading = false;
      });
  }
}
