<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">
                Add New Documents
            </h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                      <li class="breadcrumb-item">
                        <a [routerLink]="['/users']">User List</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a [routerLink]="['/users', userId]">User</a>
                      </li>
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users', userId, 'documents']">Document List</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Add New Document
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Validated Kyc Status
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          In PENDING_REVIEW
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Rejected KYC
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-6">
                <div class="alert alert-success alert-dismissable" role="alert" *ngIf="alert.display">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p class="mb-0"> {{ alert.message }} </p>
                </div>
                <div class="js-wizard-simple block">
                    <!-- Form -->
                    <form [formGroup]="addNewDocumentForm" novalidate (ngSubmit)="addNewDocument()">
                        <!-- Steps Content -->
                        <div class="block-content block-content-full tab-content px-md-5" style="min-height: 300px;">
                            <!-- Step 1 -->
                            <div class="tab-pane active" id="wizard-simple-step1" role="tabpanel">
                                <div class="form-group">
                                    <label for="type">Document Type</label>
                                    <select class="form-control" id="documentType" name="type"
                                        formControlName="type">
                                        <option value="0">Please select</option>
                                        <option *ngFor= "let type of documentTypes" value="{{type.name}}">{{type.name}}</option>
                                    </select>
                                </div>


                                <div class="form-group">
                                    <label>Document</label>
                                    <div class="custom-control custom-switch mb-1">
                                        <input type="file" (change)="onFileChange($event)" />
                                    </div>
                                </div>

                            </div>
                            <!-- END Step 1 -->
                        </div>
                        <!-- END Steps Content -->

                        <!-- Steps Navigation -->
                        <div class="block-content block-content-sm block-content-full bg-body-light rounded-bottom">
                            <div class="row">
                                <div class="col-6 text-right">
                                    <button type="submit" [disabled]="addNewDocumentForm.invalid || addNewDocumentForm.prestine" class="btn btn-primary" data-wizard="finish">
                                        <!-- <i class="fa fa-check mr-1"></i> Submit -->
                                        <i [ngClass]="{'fa fa-asterisk fa-spin': inProgress}"></i> Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- END Steps Navigation -->
                    </form>
                    <!-- END Form -->
                </div>
            </div>
        </div>
    </div>
