<div class="bg-body-light">
    <div class="content content-full">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
            <h1 class="flex-sm-fill h3 my-2">Wallet Account User</h1>
        </div>
    </div>
</div>
<div class="bg-white p-3 push">
    <!-- Toggle Navigation -->
    <div class="d-lg-none">
        <!-- Class Toggle, functionality initialized in Helpers.coreToggleClass() -->
        <button type="button"
                class="btn btn-block btn-light d-flex justify-content-between align-items-center"
                data-toggle="class-toggle"
                data-target="#horizontal-navigation-hover-centered"
                data-class="d-none">
            Menu - Hover Centered
            <i class="fa fa-bars"></i>
        </button>
    </div>
    <!-- END Toggle Navigation -->

    <div class="col-xl-6">
        <div class="block">
            <div class="block-content">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb push">
                        <li class="breadcrumb-item">
                            <a [routerLink]="['/users']">User List</a>
                        </li>
                        <li class="breadcrumb-item active"
                            [routerLink]="['/users', user.userId]"
                            aria-current="page">
                            User
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <!-- Navigation -->
    <div id="horizontal-navigation-hover-normal"
         class="d-none d-lg-block mt-2 mt-lg-0"
         *ngIf="!loading">
        <ul class="nav-main nav-main-horizontal nav-main-hover">
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'accounts']">
                    <i class="nav-main-link-icon si si-wallet"></i>
                    <span class="nav-main-link-name">Accounts</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'beneficiaries']">
                    <i class="nav-main-link-icon si si-users"></i>
                    <span class="nav-main-link-name">Beneficiaries</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'transfers']">
                    <i class="nav-main-link-icon fa fa-paper-plane"></i>
                    <span class="nav-main-link-name">Transfers</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'payment-means']">
                    <i class="nav-main-link-icon fa fa-money-bill-wave"></i>
                    <span class="nav-main-link-name">Payouts</span>
                </a>
            </li>

            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'documents']">
                    <i class="nav-main-link-icon fa fa-file"></i>
                    <span class="nav-main-link-name">Documents</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'bank-details']">
                    <i class="nav-main-link-icon fa fa-credit-card"></i>
                    <span class="nav-main-link-name">Bank Details</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'tontines']">
                    <i class="nav-main-link-icon fa fa-users"></i>
                    <span class="nav-main-link-name">Tontines</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'bank-payloads']">
                    <i class="nav-main-link-icon fa fa-university"></i>
                    <span class="nav-main-link-name">Bank Payloads</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'wallet-events']">
                    <i class="nav-main-link-icon fa fa-university"></i>
                    <span class="nav-main-link-name">Wallet Events</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'support-cases']">
                    <i class="nav-main-link-icon fa fa-briefcase"></i>
                    <span class="nav-main-link-name">Support Cases</span>
                </a>
            </li>
            <li class="nav-main-item">
                <a class="nav-main-link active"
                   [routerLink]="['/users', user.userId, 'comments']">
                    <i class="nav-main-link-icon fa fa-comment"></i>
                    <span class="nav-main-link-name">Comments</span>
                </a>
            </li>
        </ul>
    </div>
    <!-- END Navigation -->
</div>
<div class="js-wizard-simple block">
    <div class="text-center"
         *ngIf="loading"
         style="
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    ">
        <div class="spinner-border"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow"
             style="width: 3rem; height: 3rem"
             role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="content"
     *ngIf="!loading">
    <div class="row">
        <div class="col-md-12">
            <div class="js-wizard-simple block">
                <!-- add loading here -->

                <!-- Form -->
                <form [formGroup]="walletUserForm">
                    <div class="block">
                        <div class="block-header">
                            <h3 class="block-title"></h3>
                            <div class="block-options">
                                <button class="btn btn-sm btn-success mx-1"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#validateUserModal">
                                    <i class="nav-main-link-icon fa fa-check"></i>
                                    <span class="ml-1">Create Bank Customer</span>
                                </button>

                                <div class="dropdown mx-1">
                                    <button type="button"
                                            class="btn btn-sm btn-primary dropdown-toggle"
                                            id="dropdown-wallet"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                        Money-Trans
                                    </button>
                                    <div class="dropdown-menu fs-sm"
                                         aria-labelledby="dropdown-wallet">
                                        <a type="button"
                                           data-toggle="modal"
                                           data-target="#updateBankCustomerDetailsModal"
                                           class="dropdown-item"
                                           href="javascript:void(0)">Send To MoneyTrans</a>
                                        <a class="dropdown-item"
                                           href="javascript:void(0)"
                                           type="button"
                                           data-toggle="modal"
                                           data-target="#emailMTContractModal">Email MoneyTrans Contract</a>
                                    </div>
                                </div>

                                <div class="dropdown ml-1 mr-4">
                                    <button type="button"
                                            class="btn btn-sm btn-primary dropdown-toggle"
                                            id="dropdown-wallet"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                        Wallet
                                    </button>
                                    <div class="dropdown-menu fs-sm"
                                         aria-labelledby="dropdown-wallet">
                                        <a type="submit"
                                           class="dropdown-item"
                                           href="javascript:void(0)">Update</a>
                                        <a class="dropdown-item"
                                           href="javascript:void(0)"
                                           type="button"
                                           data-toggle="modal"
                                           data-target="#sendEmail">Send Email</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row mx-md-5 align-items-center">
                        <div class="progress"
                             style="height: 10px; width: 95%;"
                             role="progressbar"
                             aria-valuemin="0"
                             aria-valuemax="100">
                            <div class="progress-bar"
                                 [style.width.%]="percentageOfCompletion"></div>
                        </div>
                        <div class="ml-4">
                            {{ percentageOfCompletion }}%
                        </div>
                    </div>

                    <div style="font-size: 0.675rem;"
                         class="row px-md-5 content-heading">
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Address</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.address.value === 'NOT_PROVIDED', 'text-primary': statuses.address.value === 'PROVIDED'}">
                                <i class="fa fa-fw fa-check"></i>
                            </div>
                        </div>
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Additional Information</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.additionalInfo.value === 'NOT_PROVIDED', 'text-primary': statuses.additionalInfo.value === 'PROVIDED'}">
                                <i class="fa fa-fw fa-check"></i>
                            </div>
                        </div>
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Proof of Address</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.proofOfAddress.value === 'NOT_PROVIDED' || statuses.proofOfAddress.value === 'VERIFYING', 'text-primary': statuses.proofOfAddress.value === 'PROVIDED'}">
                                <i *ngIf="statuses.proofOfAddress.value === 'NOT_PROVIDED' || statuses.proofOfAddress.value === 'PROVIDED'"
                                   class="fa fa-fw fa-check">
                                </i>

                                <i *ngIf="statuses.proofOfAddress.value === 'VERIFYING'"
                                   class="far fa-hourglass-half">
                                </i>
                            </div>
                        </div>
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Identity Check</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.EKYC.value === 'NOT_PROVIDED', 'text-primary': statuses.EKYC.value === 'PROVIDED'}">
                                <i class="fa fa-fw fa-check"></i>
                            </div>
                        </div>
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Contract</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.contract.value === 'NOT_SIGNED', 'text-primary': statuses.contract.value === 'SIGNED'}">
                                <i class="fa fa-fw fa-check"></i>
                            </div>
                        </div>
                        <div class="col-2 d-flex">
                            <div class="fw-normal">Subscription</div>

                            <div class="mx-1"
                                 [ngClass]="{'opacity-25': statuses.subscription.value === 'NOT_PAID', 'text-primary': statuses.subscription.value === 'PAID'}">
                                <i class="fa fa-fw fa-check"></i>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-md-5 border-bottom"></div>

                  <div style="font-size: 0.675rem;"
                       class="row px-md-5 content-heading">
                    <div class="mx-1">
                      Registration Date : {{ convertToTime(user.createdAt)}}
                    </div>
                    |
                    <div class="mx-1">
                      App Version : {{ user.device.appVersion}}
                    </div>
                    |
                    <div class="mx-1">
                      Device Model : {{ user.device.deviceModel}}
                    </div>
                    |
                    <div class="mx-1">
                      Device OS : {{ user.device.deviceOs}}
                    </div>
                    |
                    <div class="mx-1">
                      Device OS Version: {{ user.device.osVersion}}
                    </div>
                    |
                    <div class="mx-1">
                      Device Language: {{ user.device.deviceLocale}}
                    </div>
                  </div>

                    <!-- Steps Content -->
                    <div class="block-content block-content-full tab-content px-md-5"
                         style="min-height: 300px">
                        <!-- Step 1 -->
                        <div class="tab-pane active"
                             id="wizard-simple-step1"
                             role="tabpanel">
                            <div class="row">
                                <div class="col-md-6">
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        General Information
                                    </h2>
                                    <div class="form-group">
                                        <label for="firstName">Firstname</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="firstName"
                                               name="firstName"
                                               formControlName="firstName" />
                                    </div>
                                    <div class="form-group">
                                        <label for="lastName">Lastname</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="lastName"
                                               name="lastName"
                                               formControlName="lastName" />
                                    </div>
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Contacts
                                    </h2>
                                    <div class="form-group">
                                        <label for="mobileNumber">Mobile Number</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="mobileNumber"
                                               name="mobileNumber"
                                               formControlName="mobileNumber" />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="email"
                                               name="email"
                                               formControlName="email" />
                                    </div>
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Address
                                    </h2>
                                    <div formGroupName="address">
                                        <div class="form-group">
                                            <label for="streetName">Street Name</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="streetName"
                                                   name="streetName"
                                                   formControlName="streetName" />
                                        </div>
                                        <div class="form-group">
                                            <label for="streetNumber">Street Number</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="streetNumber"
                                                   name="streetNumber"
                                                   formControlName="streetNumber" />
                                        </div>
                                        <div class="form-group">
                                            <label for="postCode">Post Code</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="postCode"
                                                   name="postCode"
                                                   formControlName="postCode" />
                                        </div>
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="city"
                                                   name="city"
                                                   formControlName="city" />
                                        </div>
                                        <div class="form-group">
                                            <label for="state">State</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="state"
                                                   name="state"
                                                   formControlName="state" />
                                        </div>
                                        <div class="form-group">
                                            <label for="state">Country</label>
                                            <input class="form-control"
                                                   readonly
                                                   type="text"
                                                   id="country"
                                                   name="country"
                                                   formControlName="country" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        MoneyTrans AML
                                    </h2>
                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="amlResult">Result</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="amlResult"
                                               name="amlResult"
                                               value="{{ amlDetails.amlResult }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="amlResultDetails">Details</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="amlResultDetails"
                                               name="amlResultDetails"
                                               value="{{ amlDetails.result }}" />
                                    </div>

                                    <!-- <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="amlExistingCustomer">Existing Customer</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="amlExistingCustomer"
                                               name="amlExistingCustomer"
                                               value="{{ amlDetails.existingCustomer }}" />
                                    </div> -->

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="amlExistingCustomer"
                                                   name="amlExistingCustomer"
                                                   disabled
                                                   [checked]="amlDetails.existingCustomer === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="amlExistingCustomer">
                                                Existing Customer
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="mainManualNote">Manual Note</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="mainManualNote"
                                               name="mainManualNote"
                                               value="{{ amlDetails.mainManualNote }}" />
                                    </div>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Bank Account Identifiers
                                    </h2>
                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="amlBankCustomerID">Bank Customer ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="amlBankCustomerID"
                                               name="amlBankCustomerID"
                                               value="{{ amlDetails.bankCustomerId }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="customerMTBankAccountId">Bank Account ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="customerMTBankAccountId"
                                               name="customerMTBankAccountId"
                                               value="{{ enrollmentContract.customerMTBankAccountId }}" />
                                    </div>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        NBK Risk profile
                                    </h2>
                                    <div class="form-group">
                                        <label for="riskProfiles">Level</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="riskProfiles"
                                               name="riskProfiles"
                                               formControlName="riskProfiles" />
                                    </div>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Enrollment Contract
                                    </h2>
                                    <!-- <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractDownloaded">Downloaded From MoneyTrans</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractDownloaded"
                                               name="enrollContractDownloaded"
                                               value="{{ enrollmentContract.downloaded }}" />
                                    </div> -->

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="downloadFromMoneyTrans"
                                                   name="downloadFromMoneyTrans"
                                                   disabled
                                                   [checked]="enrollmentContract.downloaded === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="downloadFromMoneyTrans">Downloaded From MoneyTrans</label>
                                        </div>
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="enrollContractPublished"
                                                   name="enrollContractPublished"
                                                   disabled
                                                   [checked]="enrollmentContract.published === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="enrollContractPublished">Sent To The Customer</label>
                                        </div>
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="enrollContractSigned"
                                                   name="enrollContractSigned"
                                                   disabled
                                                   [checked]="enrollmentContract.signed === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="enrollContractSigned">Signed</label>
                                        </div>
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="enrollContractSent"
                                                   name="enrollContractSent"
                                                   disabled
                                                   [checked]="enrollmentContract.submitted === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="enrollContractSent">Sent to MoneyTrans</label>
                                        </div>
                                    </div>

                                    <!-- <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractPublished">Sent To The Customer</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractPublished"
                                               name="enrollContractPublished"
                                               value="{{ enrollmentContract.published }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractSigned">Signed</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractSigned"
                                               name="enrollContractSigned"
                                               value="{{ enrollmentContract.signed }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractSent">Sent to MoneyTrans</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractSent"
                                               name="enrollContractSent"
                                               value="{{ enrollmentContract.submitted }}" />
                                    </div> -->

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractSignerId">IgniSign Signer ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractSignerId"
                                               name="enrollContractSignerId"
                                               value="{{ enrollmentContract.igniSignSignerId }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractDocumentId">IgniSign Document ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractDocumentId"
                                               name="enrollContractDocumentId"
                                               value="{{ enrollmentContract.igniSignDocumentId }}" />
                                    </div>

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractSignatureRequestId">IgniSign Signature Request
                                            ID</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractSignatureRequestId"
                                               name="enrollContractSignatureRequestId"
                                               value="{{enrollmentContract.igniSignsignatureRequestId}}" />
                                        <a target="_blank"
                                           href="{{enrollmentContract.igniSignsignatureRequestUrl}}">
                                            Signature Request Details
                                        </a>
                                    </div>

                                    <!-- <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <label for="enrollContractSubmitted">Send to MoneyTrans</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="enrollContractSubmitted"
                                               name="enrollContractSubmitted"
                                               value="{{ enrollmentContract.submitted }}" />
                                    </div> -->

                                    <div class="form-group"
                                         formGroupName="userKycDetails">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   id="enrollContractSubmitted"
                                                   name="enrollContractSubmitted"
                                                   disabled
                                                   [checked]="enrollmentContract.submitted === 'Yes'">
                                            <label class="custom-control-label font-w400"
                                                   for="enrollContractSubmitted">Send to MoneyTrans</label>
                                        </div>
                                    </div>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Transaction Stats
                                    </h2>
                                    <div class="form-group">
                                        <label for="lastTransactionDate">Last transaction date</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="lastTransactionDate"
                                               name="lastTransactionDate"
                                               formControlName="lastTransactionDate" />
                                    </div>

                                    <h2 class="content-heading border-bottom mb-4 pb-2">
                                        Subscription Details
                                    </h2>
                                    <div class="form-group"
                                         formGroupName="subscriptionPlan">
                                        <label for="code">Plan</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="code"
                                               name="code"
                                               formControlName="code" />
                                    </div>
                                    <div class="form-group">
                                        <label for="subscriptionBillingPeriod">Billing Period</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="subscriptionBillingPeriod"
                                               name="subscriptionBillingPeriod"
                                               formControlName="subscriptionBillingPeriod" />
                                    </div>
                                    <div class="form-group">
                                        <label for="subscriptionStatus">Subscription Status</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="subscriptionStatus"
                                               name="subscriptionStatus"
                                               formControlName="subscriptionStatus" />
                                    </div>

                                    <div class="form-group">
                                        <label for="subscriptionPaymentStatus">Payment Status</label>
                                        <input class="form-control"
                                               readonly
                                               type="text"
                                               id="subscriptionPaymentStatus"
                                               name="subscriptionPaymentStatus"
                                               formControlName="subscriptionPaymentStatus" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END Step 1 -->
                    </div>
                    <!-- END Steps Content -->
                </form>
                <!-- END Form -->
            </div>
        </div>
    </div>
</div>

<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="updateBankCustomerDetailsModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="updateBankCustomerDetailsTitle"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="updateBankCustomerDetailsTitle">Bank Customer Details</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you confirm, you want to publish the customer details? The data will be sent to
                <strong>MoneyTrans</strong> alongside the user <strong> KYC documents </strong>.
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="updateBankUserDetails()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Update details</button>
            </div>
        </div>
    </div>
</div>

<!-- Confirm Bank Customer update -->
<div class="modal fade"
     id="validateUserModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="validateUser"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="validateUser">Bank Customer Details</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you confirm the user has provided all the required information for the bank account creation.
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="onValidate()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Confirm</button>
            </div>
        </div>
    </div>
</div>

<!-- Send COntract to MoneyTrans -->
<div class="modal fade"
     id="emailMTContractModal"
     tabindex="-1"
     role="dialog"
     aria-labelledby="emailMTContractTitle"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="emailMTContractTitle">Send MoneyTrans Contract To Customer</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Do you confirm, you want to resend the <strong>MoneyTrans Contract</strong> to the customer? This will
                generate a new Signature request.
                Please make sure before your confirm that the first email has expired. <a target="_blank"
                   href="{{enrollmentContract.igniSignsignatureRequestUrl}}">Signature Request Details</a>
            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="publishSignatureRequest()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Send</button>
            </div>
        </div>
    </div>
</div>


<!-- Send Email -->
<div class="modal fade"
     id="sendEmail"
     tabindex="-1"
     role="dialog"
     aria-labelledby="sendEmail"
     aria-hidden="true">
    <div class="modal-dialog"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"
                    id="emailMTContractTitle">Send Email To Customer</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <select class="custom-select"
                        id="example-select"
                        name="example-select"
                        [(ngModel)]="selectedTemplate">
                    <option *ngFor="let item of emailTemplates"
                            [ngValue]="item.template">{{ item.template }}</option>
                </select>

            </div>
            <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary btn-sm"
                        data-dismiss="modal">Cancel</button>
                <button (click)="sendEmail()"
                        data-dismiss="modal"
                        type="button"
                        class="btn btn-primary btn-sm">Send</button>
            </div>
        </div>
    </div>
</div>
