import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertEntity } from 'src/app/alert/alert-entity';
import { AuthService } from 'src/app/auth/auth.service';
import { DocumentTypeCollection } from 'src/app/compliance-api/documents/types/document-type-collection';
import { DocumentTypeEntity } from 'src/app/compliance-api/documents/types/document-type-entity';
import { DocumentTypeEntityService } from 'src/app/compliance-api/documents/types/document-type-entity.service';
import { UserDocumentsService } from 'src/app/compliance-api/documents/user-documents.service';
import { WalletApiResponse } from 'src/app/wallet-api/wallet-api-response';

@Component({
  selector: 'app-add-user-documents',
  templateUrl: './add-user-documents.component.html',
  styleUrls: ['./add-user-documents.component.css']
})
export class AddUserDocumentsComponent implements OnInit {

  addNewDocumentForm;
  alert: AlertEntity;
  inProgress: boolean;
  userId: string;
  loading: boolean;
  documentTypes: Array<DocumentTypeEntity>

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private userDocumentService: UserDocumentsService,
    private documentTypeService: DocumentTypeEntityService
  ) {

    this.userId = this.route.snapshot.paramMap.get('userId');

    this.addNewDocumentForm = this.formBuilder.group({
      type: [null, Validators.required],
      document: [null, Validators.required],
      fileName: [null, Validators.required]
    });

    this.alert = {
      display: false,
      message: ''
    };
  }

  ngOnInit(): void {
    this.authService.token().subscribe(() => {
      this.documentTypeService.fetchAll().subscribe((response: WalletApiResponse<DocumentTypeCollection>) => {
        this.documentTypes = response.data.DocumentTypes;
      });
    });
  }

  onFileChange(event) {
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsBinaryString(file);

      reader.onload = () => {
        this.addNewDocumentForm.patchValue({
          document: file,
          fileName: file.name
        });

        this.cd.markForCheck();
      };
    }
  }

  addNewDocument(){
    this.inProgress = true;
    const formData = new FormData();
    formData.append('content',
     this.addNewDocumentForm.get('document').value,
     this.addNewDocumentForm.get('fileName').value
     );
    formData.append('type', this.addNewDocumentForm.get('type').value);

    this.authService.token().subscribe(() => {
      this.userDocumentService.add(this.userId, formData).subscribe((response) => {
        this.inProgress = false;
        this.alert.display = true;
        this.alert.message = 'Successfully added document ';
        window.location.href = '/users/' + this.userId + '/documents';
      });
    });

  }

}
